// @ts-nocheck
import * as React from "react";
import { AppBar } from "react-admin";
import { ReactComponent as Logo } from "../../../assets/vectors/Exly_Primary_Main.svg";
import { ReactComponent as LogoWhite } from "../../../assets/vectors/ExlyWhite.svg";
import Feedback from "../../modules/Feedback";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import NotificationsIcon from "@material-ui/icons/Notifications";
import dataProvider from "../../../data/dataProvider";
import api from "../../../data/APIs";
import useStyles from "./AppBar.styles";
import ProfileActions from "./components/ProfileActions/ProfileActions";

import {
  Tooltip,
  ThemeProvider,
  creatorToolTheme,
} from "@my-scoot/component-library-legacy";
import styles from "features/UILayout/modules/Menu/MenuStyles.module.css";
import { ShowTourSuggestionBackdrop } from "features/Onboarding/modules/WhatsNext/modules/ShowTourSuggestionBackdrop/ShowTourSuggestionBackdrop";
import { logError } from "utils/error";
import FeatureAnnouncementBadge from "../../../features/FrillWidgets/FeatureAnnouncement/FeatureAnnouncementBadge";

const CustomAppBar = (props: any) => {
  const isDesktop = useMediaQuery("(min-width: 767px)");
  const [isNotificationSeen, setIsNotificationSeen] = React.useState(true);

  const handleNotification = async () => {
    if (!isNotificationSeen) {
      try {
        await dataProvider.custom_request(
          `notifications/exly/webpush/updateseen`,
          "POST",
          ""
        );
      } catch (err) {
        logError({
          error: err,
          occuredAt: "src/ui/layout/AppBar/AppBar.tsx",
          when: "calling handleNotification",
        });
      }
    }
    setIsNotificationSeen(true);
    window.location.href = `${window.location.origin}/#/Notifications`;
  };

  React.useEffect(() => {
    (async () => {
      try {
        let status = await dataProvider.custom_request(
          `notifications/exly/webpush/fetch`,
          "GET",
          { page_number: 1, page_size: 50 }
        );
        const apiResponse = await dataProvider.custom_request(
          api.get_notification_status,
          "GET"
        );

        setIsNotificationSeen(apiResponse?.data?.is_notification_seen);

        window.sessionStorage.setItem(
          "notificationData",
          JSON.stringify(status)
        );
      } catch (err) {
        logError({
          error: err,
          occuredAt: "src/ui/layout/AppBar/AppBar.tsx",
          when: "calling get_notification_status",
        });
        if (err) {
          window.sessionStorage.setItem("notificationData", "");
        }
      }
    })();
  }, []);

  const classes = useStyles();

  const userMenu = (
    <>
      <ShowTourSuggestionBackdrop
        backdropClass={styles.overrideAppBardropStyle}
      />
      <div className={classes.desktopLogoWrapper}>
        <Logo />
      </div>

      {/* <span className={classes.spacer} /> */}

      <ThemeProvider theme={creatorToolTheme}>
        <Tooltip arrow color="primary" title={isDesktop ? "Notifications" : ""}>
          <span
            className={classes.notification_wrapper}
            onClick={handleNotification}
          >
            {!isNotificationSeen ? (
              <span className={classes.redDot}></span>
            ) : (
              <></>
            )}
            <NotificationsIcon
              style={{
                fontSize: 32,
                color: "#6E675E",
              }}
            />
          </span>
        </Tooltip>
      </ThemeProvider>
      <FeatureAnnouncementBadge width="45px" height="45px" color="#6E675E" />
      <Feedback width="32px" height="32px" color="#6E675E" />
      <ProfileActions width="32px" height="32px" color="#6E675E" />
    </>
  );

  const userMenuMobile = (
    <>
      <ShowTourSuggestionBackdrop
        backdropClass={styles.overrideMobileAppBardropStyle}
      />
      <div className={classes.mobileLogoWrapper}>
        <LogoWhite />
      </div>
      {/* <span className={classes.spacer} /> */}

      <span
        style={{ position: "relative", marginRight: "14px" }}
        onClick={handleNotification}
      >
        {!isNotificationSeen ? (
          <span className={classes.redDotMobile}></span>
        ) : (
          <></>
        )}
        <NotificationsIcon
          style={{
            fontSize: 24,
            color: "#fff",
          }}
        />
      </span>
      <FeatureAnnouncementBadge width="36px" height="36px" color="#fff" />
      <Feedback width="24px" height="24px" color="#fff" />
      <ProfileActions width="24px" height="24px" color="#fff" />
    </>
  );

  return isDesktop ? (
    <AppBar
      {...props}
      userMenu={userMenu}
      classes={{
        title: classes.desktopAppBar,
        menuButton: classes.menuButton,
      }}
      elevation={1}
    />
  ) : (
    <AppBar
      {...props}
      className={classes.mobileAppBar}
      elevation={1}
      container={React.Fragment}
      userMenu={userMenuMobile}
    />
  );
};

export default CustomAppBar;
